




















































































































import {ComponentBasic} from "@/utilities/UtilitiesVue";
import {RestClientApergia} from "@/utilities/RestClientApergia";
import Component, {mixins} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Component({})
export default class extends mixins(ComponentBasic) {

    LN: string = "[PageAdminSettings]::";
    options: any = {mode: 'json'};
    /*NOT USED*/
    isTopBarCompact: boolean = false;
    isShowSideBar: boolean = false;

    /*Calendar*/
    calendarBaseURL: string = 'http://www.google.com/calendar/embed?showTitle=0&showNav=0&showDate=1&showPrint=1&showTabs=1&showCalendars=0&showTz=0&mode=MONTH&wkst=2&bgcolor=%23FFFFFF&hl=en-GB&height=';
    calendarHeightPx: number = 1200;

    /*Background*/
    backColor: string | null = null;
    backImage: string | null = null;

    jsonConfigMarket: any | null = null;

    /*Migrate Dates*/
    menuDateFrom: boolean = false;
    menuDateTo: boolean = false;
    dateFrom: string = new Date().toISOString().substr(0, 10);
    dateTo: string = new Date().toISOString().substr(0, 10);
    btnMigrateDisable: boolean = false;
    btnNameDaysDisable: boolean = false

    /*Reimport Namedays?*/

    created() {
        this.Log("Created ");
        let dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 15);
        this.dateFrom = dateFrom.toISOString().substr(0, 10);

        let dateTo = new Date();
        dateTo.setDate(dateTo.getDate() + 15);
        this.dateTo = dateTo.toISOString().substr(0, 10);
    }

    mounted() {
        this.Log("Mounted (display the setting from x)");
        this.onUpdateUI();
    }

    onUpdateUI() {
        this.Log("Set model in UI");
        if (this.settings != null) {
            //@ts-ignore
            this.backColor = this.settings.backColor;
            //@ts-ignore
            this.backImage = this.settings.backImage;
            this.jsonConfigMarket = JSON.stringify(this.settings.configMarket, null, 2);
            //@ts-ignore
            this.calendarBaseURL = this.settings.calendarBaseURL;
            //@ts-ignore
            this.calendarHeightPx = this.settings.calendarHeightPx;
            //@ts-ignore
            this.isTopBarCompact = this.settings.isTopBarCompact;
            //@ts-ignore
            this.isShowSideBar = this.settings.isShowSideBar;
        } else {
            this.Log("Settings is not yet loaded.. unable to updated ui");
            //TODO set a flag ?
        }
    }

    onSave() {
        this.Log("Saving Settings");

        /*See class 'gr.apergia.model.config.ConfigurationServer' structure to be consisten*/
        let settings = {
            calendarBaseURL: this.calendarBaseURL,
            calendarHeightPx: this.calendarHeightPx,

            isShowSideBar: this.isShowSideBar,
            isTopBarCompact: this.isTopBarCompact,

            configMarket: (this.jsonConfigMarket == null || this.jsonConfigMarket.trim() == '') ? null : JSON.parse(this.jsonConfigMarket),
            backImage: this.backImage,
            backColor: this.backColor
        };
        this.Log("Trying to save settings", settings);

        let formData = new FormData();
        //@ts-ignore
        formData.set('token', this.getToken());
        formData.set('settings', JSON.stringify(settings));

        RestClientApergia.adminSaveConfig(
            val => {
                this.EventThrow('notify', "Saved");
                this.LoadSettings();
            },
            val => {
                this.EventThrow('notify', "Failed !!! " + val);
            },
            formData);
    }

    onMigrate() {
        this.btnMigrateDisable = true;
        //@ts-ignore
        RestClientApergia.adminImportCalendar(
            val => {
                this.EventThrow('notify', "Calendar Migration Completed [" + val.data + "]");
                this.btnMigrateDisable = false;
            }, val => {
                this.EventThrow('notify', "Calendar Migration Failed !!! " + val);
                this.btnMigrateDisable = false;
            },
            this.getToken(), new Date(this.dateFrom).getTime(), new Date(this.dateTo).getTime());
    }

    onNameDays() {
        this.btnNameDaysDisable = true;
        RestClientApergia.adminImportNameDays(
            val => {
                this.EventThrow('notify', "Nameday migrations completed [" + val.data + "]");
                this.btnNameDaysDisable = false;
            }, val => {
                this.EventThrow('notify', "Calendar Migration Failed !!! " + val);
                this.btnNameDaysDisable = false;
            },
            this.getToken());
    }

    /*On Change content/settings from server*/
    @Watch('settings')
    onSettingsChange(val: any) {
        this.Log("Watcher, on settings updated");
        this.onUpdateUI();
    }

    @Watch('fieldType')
    onFieldTypeChange(val: any) {
        //@ts-ignore
        if (this.getContentMap != null) {
            let structure = this.getContent(val);
            //@ts-ignore
            this.fieldContent = structure.content;
            //@ts-ignore
            this.fieldData = structure.getData();
            //@ts-ignore
            this.fieldSideBar = structure.hasCommonSideBar();
        } else {
            //@ts-ignore
            this.fieldContent = 'n/a';
            //@ts-ignore
            this.fieldData = 'n/a';
        }
    }
}
